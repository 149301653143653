<template>
  <div class="duide_details_container">
    <div class="row guide_container">
      <div class="title">
        <p class="mb20">{{info.titleEn}}</p>
        <p class="title_cn">{{info.titleCn}}</p>
      </div>
      <div class="options_box no_select mb20">
        <span v-if="info.qiniuUrl" class="hand options_btn flc" @click="toPdfDeatils">
          <img class="pdf_icon" src="@/assets/img/docuInfo/pdf_icon.png" alt="">
          阅读
        </span>
        <collection v-if="!isCollect" ref="collection" :itemId="id" :category="10"
                    @upDataType="collectStatus" />

        <span v-else @click="cancelCollect" class=" hand isCollect options_btn flc">
          <i class="iconfont icon-a-shoucangshoucanghouicon"></i>
          已收藏
        </span>
      </div>

      <div class="details_container card">
        <p>制定者：{{info.framer}}</p>
        <p>出处：{{info.provenance}}</p>
        <p>科室：{{info.ejks}}</p>
        <p>发表时间：{{$initTime(info.docPublishTime)}}</p>
      </div>

      <div class="type_title">概述</div>

      <div class="summary_container ">
        <div class="summary_content">{{info.docAbstract}}</div>
        <div class="summary_content">{{info.docAbstractZh}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import guide from '@/api/guideline.js'
import { collectStatus, callCollect } from '@/api/article.js'
import { blankPagePar } from '@/utils/index.js'
import { mapGetters } from 'vuex'
export default {
  props: {},
  components: {},
  data () {
    return {
      id: null,
      info: {},
      isCollect: false
    }
  },
  methods: {
    cancelCollect () {
      this.$confirm('确认取消收藏该指南吗？', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(async () => {
        const res = await callCollect({
          category: 10,
          itemId: this.id,
          userId: this.userId
        })
        if (res.code !== 0) return this.$message.error('取消收藏失败, 请重试')
        this.isCollect = false
        this.$message.success('取消收藏成功')
      }).catch((e) => {
        if (e !== 'cancel') return this.$message.error('操作失败')
      })
    },
    async collectStatus () {
      try {
        const res = await collectStatus({
          category: 10,
          itemId: this.id,
          userId: this.userId
        })
        this.isCollect = res.data
      } catch (error) {
        return this.$message.error('查询文章收藏状态失败')
      }
    },
    toPdfDeatils () {
      if (!this.userId) return this.$login()
      blankPagePar('pdfDetails', { id: this.id, category: 10 })
    },
    async getInfo () {
      const res = await guide.guideDetails({
        guidelineId: this.id
      })
      this.info = res.data
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created () {
    this.id = this.$route.query.id
    this.getInfo()
    if (!this.userId) return
    this.collectStatus()
  }
}
</script>

<style lang="less" scoped>
.duide_details_container {
  min-height: calc(100vh - 255px);
  background-color: #f5f7fb;
}
.guide_container {
  padding: 20px 0 30px;
  .title {
    font-size: 26px;
    margin-bottom: 10px;
    line-height: 1.5em;
    font-weight: bold;
    color: #000000;
    padding-left: 20px;
  }
  .title_cn {
    font-size: 26px;
  }
}

.options_box {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: start;
  padding-left: 20px;
  .options_btn {
    position: relative;
    top: 0;
    width: 90px;
    height: 32px;
    border-radius: 5px;
    margin: 0 30px 0px 0;
    font-size: 14px;
    color: #4b639f;
    border: 1px solid #4b639f;
    &:hover {
      top: -2px;
      transition: all 0.3s;
    }
    &:first-child {
      background: #4b639f;
      color: #fff;
    }
  }
  .pdf_icon {
    width: 16px;
    height: 16px;
    margin-right: 3px;
  }
  .isCollect {
    border: 1px solid #4b639f;
    background-color: #f5f7fb;
    color: #4b639f;
  }
}

.details_container {
  padding: 20px;
  box-shadow: 0 0 6px 0 #e8e8e8;
  border-radius: 5px;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
.type_title {
  height: 40px;
  padding: 0 20px;
  margin-top: 10px;
  font-weight: bold;
  line-height: 40px;
  font-size: 18px;
}
.summary_container {
  display: grid;
  grid-template-columns: 780px 360px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  font-size: 16px;
  line-height: 1.5em;
  color: #000;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 #e8e8e8;
  border-radius: 5px;
  .summary_content {
    padding: 20px;
    background-color: #f5f7fb;
    border-radius: 5px;
  }
}
</style>
